import _T from '../locale/AgencyPortal/translator';
const OrderStatusEnum = 
{
    1 : { text: "Pending" , html: <span className="badge-warning badge-soft-warning font-size-12 badge badge-pill">Pending</span>},
    2 : { text: "Payment Done" , html: <span className="badge-info badge-soft-info font-size-12 badge badge-pill">Payment Done</span>},
    3 : { text: "Waiting for Performer" , html: <span className="badge-warning badge-soft-warning font-size-12 badge badge-pill">Waiting for Performer</span>},
    4 : { text: "Waiting for Seeker" , html: <span className="badge-warning badge-soft-warning font-size-12 badge badge-pill">Waiting for Seeker</span>},
    5 : { text: "Accepted" , html: <span className="badge-success badge-soft-success font-size-12 badge badge-pill">Accepted</span>},
    6 : { text: "Rejected" , html: <span className="badge-danger badge-soft-danger font-size-12 badge badge-pill">Rejected</span>},
    7 : { text: "Cancelled" , html: <span className="badge-danger badge-soft-danger font-size-12 badge badge-pill">Cancelled</span>},
    8 : { text: "In-Progress" , html: <span className="badge-warning badge-soft-warning font-size-12 badge badge-pill">In-Progress</span>},
    9 : { text: "Completed" , html: <span className="badge-success badge-soft-success font-size-12 badge badge-pill">Completed</span>},
    10 : { text: "Refunded" , html: <span className="badge-secondary badge-soft-secondary font-size-12 badge badge-pill">Refunded</span>},
    // 1 : { text: "Pending" , html: <span className="badge-warning badge-soft-warning font-size-12 badge badge-pill">{_T("Pending")}</span>},
    // 2 : { text: "Payment Done" , html: <span className="badge-info badge-soft-info font-size-12 badge badge-pill">{_T("Payment Done")}</span>},
    // 3 : { text: "Waiting for Performer" , html: <span className="badge-warning badge-soft-warning font-size-12 badge badge-pill">{_T("Waiting for Performer")}</span>},
    // 4 : { text: "Waiting for Seeker" , html: <span className="badge-warning badge-soft-warning font-size-12 badge badge-pill">{_T("Waiting for Seeker")}</span>},
    // 5 : { text: "Accepted" , html: <span className="badge-success badge-soft-success font-size-12 badge badge-pill">{_T("Accepted")}</span>},
    // 6 : { text: "Rejected" , html: <span className="badge-danger badge-soft-danger font-size-12 badge badge-pill">{_T("Rejected")}</span>},
    // 7 : { text: "Cancelled" , html: <span className="badge-danger badge-soft-danger font-size-12 badge badge-pill">{_T("Cancelled")}</span>},
    // 8 : { text: "In-Progress" , html: <span className="badge-warning badge-soft-warning font-size-12 badge badge-pill">{_T("In-Progress")}</span>},
    // 9 : { text: "Completed" , html: <span className="badge-success badge-soft-success font-size-12 badge badge-pill">{_T("Completed")}</span>},
    // 10 : { text: "Refunded" , html: <span className="badge-secondary badge-soft-secondary font-size-12 badge badge-pill">{_T("Refunded")}</span>},
}

export { OrderStatusEnum };