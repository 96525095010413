import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap";
import { showToast } from "../../components/Toast";
import { getAgencyInvoices } from "../../helper/backend-methods";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { getUserInfo } from "../../common";
import { savePDF } from "@progress/kendo-react-pdf";
import _T from '../../locale/AgencyPortal/translator';


const Invoices = () => {
  const [loading, setLoading] = useState(true)
  const [invoices, setInvoices] = useState([])
  const [showInvoices, setShowInvoices] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState(null)

  useEffect(() => {
    getAgencyInvoices({ ispaid: true }).then(({ data }) => {
      if (data.status) {
        setInvoices(data.data)
      }
      else {
        showToast("error", "Request Failed", data.message)
      }
      setLoading(false)
    }).catch((err) => {
      showToast("error", "Request Failed", err.message)
      setLoading(false)
    });
  }, [])

  const returnCard = (json) => {
    try {
      let object = JSON.parse(json);
      return "**** **** **** " + object.card_number.slice(-4);
    } catch (error) {
      return "**** **** **** ****";
    }
  }

  const displayInvoice = (data) => {
    setSelectedInvoice(data)
    setShowInvoices(true)
  }

  const exportInvoice = event => {
    let element = document.getElementById("invoice");
    document.getElementsByClassName("order-invoice-download")[0].classList.add("d-none")
    savePDF(element, {
      paperSize: "auto",
      margin: 20,
      fileName: "Tawkeel-Invoice-" + selectedInvoice.generated_id,
    });
    setTimeout(() => {
      document.getElementsByClassName("order-invoice-download")[0].classList.remove("d-none")
    }, 3000);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Row>
          <Col className="col-12">
            <Card>

              {
                !showInvoices ?
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <CardTitle className="h4">{_T("Your Invoices")}</CardTitle>
                        <p className="card-title-desc">{_T("Following is a list of all paid and unpaid invoices")},</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="table-container">
                          <Table hover responsive="true">
                            <thead>
                              <tr>
                                <th className="fs-12 text-gray-500">{_T("Invoice ID")}</th>
                                <th className="fs-12 text-gray-500">{_T("Invoice Date")}</th>
                                <th className="fs-12 text-gray-500">{_T("Card")}</th>
                                <th className="fs-12 text-gray-500 text-right">{_T("Vouchers")}</th>
                                <th className="fs-12 text-gray-500 text-right">{_T("Amount")}</th>
                                <th className="fs-12 text-gray-500 text-right">{_T("Tax (VAT)")}</th>
                                <th className="fs-12 text-gray-500 text-right">{_T("Total")}</th>
                                <th className="fs-12 text-gray-500 text-center">{_T("Status")}</th>
                                <th className="fs-12 text-gray-500 text-center">{_T("Action")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                loading ?
                                  [...Array(30)].map((a, i) => {
                                    return <tr key={i}>
                                      <td className="fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                                      <td className="fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                                      <td className="fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                                      <td className="fs-12 text-gray-500 text-right"><Skeleton height={12} width={40} /></td>
                                      <td className="fs-12 text-gray-500 text-right"><Skeleton height={12} width={40} /></td>
                                      <td className="fs-12 text-gray-500 text-right"><Skeleton height={12} width={40} /></td>
                                      <td className="fs-12 text-gray-500 text-right"><Skeleton height={12} width={50} /></td>
                                      <td className="fs-12 text-gray-500 text-center"><Skeleton height={12} width={50} /></td>
                                      <td className="fs-12 text-gray-500 text-center"><Skeleton height={12} width={50} /></td>
                                    </tr>
                                  })
                                  :
                                  invoices.length === 0 ?
                                    <tr><td colSpan="8" className="text-center fs-12 fw-600"><span className="d-flex align-items-center justify-content-center"><i className="ri-error-warning-line text-danger fs-22 fw-300 me-2"></i>{_T(" You currently has no transactions records.")}</span></td></tr> :
                                    invoices.map((t, index) => {
                                      return <tr key={index}>
                                        <td className="fs-12 text-gray-500">{t.generated_id}</td>
                                        <td className="fs-12 text-gray-500">{moment.utc(t.timestamp).local().format("hh:mm A   D/M/YYYY")}</td>
                                        <td className="fs-12 text-gray-500"><span className={`badge-success badge-soft-success font-size-12 badge badge-pill`}>{returnCard(t.transaction_data)}</span></td>
                                        <td className="fs-12 text-gray-500 text-right">{t.vouchers}</td>
                                        <td className="fs-12 text-gray-500 text-right">SAR {new Intl.NumberFormat({ style: 'currency', currency: 'JPY' }).format((t.service_cost))}</td>
                                        <td className="fs-12 text-gray-500 text-right">({t.vat_percentage}%) SAR {new Intl.NumberFormat({ style: 'currency', currency: 'JPY' }).format((t.service_cost * (t.vat_percentage / 100)).toFixed(2))}</td>
                                        <td className="fs-12 text-gray-500 text-right">SAR {new Intl.NumberFormat({ style: 'currency', currency: 'JPY' }).format((t.total))}</td>
                                        <td className="fs-12 text-gray-500 text-center"><span className={`badge-${t.paid ? "success" : "warning"} badge-soft-${t.paid ? "success" : "warning"} font-size-12 badge badge-pill`}>{t.paid ? _T("Paid") : _T("Unpaid")}</span></td>
                                        <td className="fs-12 text-gray-500 text-center">{t.pdf_file_name ? <a href={t.pdf_file_name} className="btn btn-primary" target="_blank" rel="noreferrer">{_T("Show Invoice")}</a> : "N/A"}</td>
                                        {/* <td className="fs-12 text-gray-500 text-center"><button className="btn btn-primary" type="button" onClick={()=>{ displayInvoice(t) }}>Show Invoice</button></td> */}
                                      </tr>
                                    })
                              }
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody> :
                  <CardBody id="invoice">
                    <Row className="mb-5 order-invoice-download">
                      <Col sm="8">
                        <h6 className="mb-0 fw-600">{_T("Order Invoice")}</h6>
                        <p className="mb-0 font-size-12">{_T("Below are the payment details for")} {selectedInvoice.generated_id}</p>
                      </Col>
                      <Col className="text-right">
                        <button type="button" className="btn btn-outline-primary" onClick={() => { setShowInvoices(false); setSelectedInvoice(null); }}>{_T("Hide Invoice")}</button>
                        <button type="button" className="btn btn-primary ml-2" onClick={() => { exportInvoice(); }}>{_T("Download Invoice")}</button>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col md={12} className="border mb-5">
                        <Row className="">
                          <Col md={6}>
                            <h6 className="mb-0 text-muted text-uppercase"><small>{_T("Invoice")}</small></h6>
                            <h6 className="mb-0 text-muted text-uppercase"><small>#<span className="text-dark">{selectedInvoice.generated_id}</span></small></h6>
                            <h6 className="mb-0 text-muted text-uppercase"><small><span className="text-dark text-capitalize">{moment(selectedInvoice.timestamp).format("hh:mm A dddd, Do MMM YYYY")}</span></small></h6>
                          </Col>
                          <Col md={6} className="text-right">
                            <h6 className="mb-0 text-muted text-uppercase text-right"><small>{_T("Agency")}</small></h6>
                            <p className="mb-0 font-size-14 text-dark font-weight-bold text-right">{getUserInfo() ? getUserInfo().name : "N/A"}</p>
                            <h6 className="mb-0 text-muted text-uppercase text-right"><small>{getUserInfo() ? getUserInfo().email : "N/A"}</small></h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col>
                        <table className="table align-middle">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-left font-size-12 text-dark w-10">#</th>
                              <th className="font-size-12 text-dark w-50">{_T("Umrah Packages")}</th>
                              <th className="font-size-12 text-dark w-25"></th>
                              <th className="font-size-12 text-dark w-15 text-right">{_T("Cost")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-left font-size-12">1</td>
                              <td className="font-size-12" colSpan={2}>{_T("Umrah Packages")} x {selectedInvoice.vouchers}</td>
                              <td className="font-size-12 text-right text-dark font-weight-bold">{new Intl.NumberFormat('en-IN').format(selectedInvoice.service_cost)} SAR</td>
                            </tr>
                            <tr>
                              <td className="border-0" colSpan={2}></td>
                              <td className="">
                                <h6 className="mb-0 text-dark fw-600 text-uppercase">
                                  <small>VAT {selectedInvoice.vat_percentage ? <span className="text-dark">({selectedInvoice.vat_percentage}%)</span> : null}</small>
                                </h6>
                              </td>
                              <td className="font-size-12 text-right text-dark font-weight-bold">{selectedInvoice.vat_percentage ? "+" : ""} {new Intl.NumberFormat('en-IN').format(selectedInvoice.vat_percentage ? ((selectedInvoice.vat_percentage / 100) * selectedInvoice.service_cost) : 0)} SAR</td>
                            </tr>
                            <tr>
                              <td className="border-0" colSpan={2}></td>
                              <td className=""><h6 className="mb-0 text-dark fw-600 text-uppercase"><small>{_T("Total")}</small></h6></td>
                              <td className="font-size-18 text-right text-dark fw-600">{new Intl.NumberFormat('en-IN').format(selectedInvoice.total)} SAR</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
              }
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Invoices;