import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import * as XLSX from 'xlsx';
import { Card, CardBody, Col, ModalBody, InputGroup, Row, Table, UncontrolledAccordion, AccordionItem, AccordionBody, Progress } from "reactstrap";
import Pagination from "react-js-pagination";
import { showToast } from "../../components/Toast";
import { OrderStatusEnum, order_countries } from "../../data";
import { createOrder, getAgencyOrders } from "../../helper/backend-methods";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import { formatBytes } from "../../common";
import Dropzone from "react-dropzone";
import { findRelationFromString } from "../../data/relations";
import sampleSheet from "../../data/sample-order-sheet-tawkeel(2).xlsx"
import Modal from "../../components/Modal"
import { relationtypes } from "../../data";
import { slots } from "../../data/slots";
import momentT from 'moment-timezone';
import { Padding } from "@mui/icons-material";
import _T from '../../locale/AgencyPortal/translator';

const OrderManagement = () => {
  const [activePage, setActivePage] = useState(1)
  const [limit, setLimit] = useState(50)
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(true)
  const [keyword, setKeyword] = useState("")
  const [selectedCountry, setSelectedCountry] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState(0)
  const [selectedDate, setSelectedDate] = useState({ startDate: 0, endDate: 0 })
  const [dateSelected, setDateSelected] = useState(false)
  const [orderData, setOrderData] = useState([])
  const [total, setTotal] = useState(0)
  const [update, setUpdate] = useState(1);
  const [screen, setScreen] = useState(1);
  const [orderModal, setOrderModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [errors, setErrors] = useState({ selectedFiles: "", codeErrors: [] });

  const [progressModal, setProgressModal] = useState(false);
  const [progress, setProgress] = useState(0)
  const orderCount = useRef(0);
  const orderProcessingIndex = useRef(0);

  // create order
  const [request, setRequest] = useState([{ service_code: "umrahalbadal", on_behalf: "", name: "", email: "", phone: "", discountCode: "", slot: "1705093200000-1705103999000", date: momentT().format("YYYY-MM-DD"), isScheduled: true, isDeceased: false }]);
  const [createOrderModal, setCreateOrderModal] = useState(false);
  const [orderQuantity, setOrderQuantity] = useState(1);
  const [orderUpdate, setOrderUpdate] = useState(1);
  const [orderErrors, setOrderErrors] = useState([{ date: "", discountCode: "", email: "", phone: "" }])

  const datePickerRef = useRef("");

  useEffect(() => {
    setLoading(true)
    getAgencyOrders({
      limit: limit,
      offset: offset,
      ...(keyword.replaceAll(/\s/g, '').length && { keyword: keyword }),
      ...(selectedCountry !== 0 && selectedCountry !== "0" && { country: selectedCountry }),
      ...(selectedStatus !== 0 && selectedStatus !== "0" && { status: selectedStatus }),
      ...(selectedDate.startDate && selectedDate.endDate && { from: selectedDate.startDate, to: selectedDate.endDate })
    }).then(({ data }) => {
      if (data.status) {
        setOrderData(data.data.orders)
        setTotal(data.data.record_count)
      }
      else {
        showToast("error", "Request Failed", data.message)
      }
      setLoading(false)
    }).catch((err) => {
      showToast("error", "Request Failed", err.message)
      setLoading(false)
    });
  }, [update, orderUpdate])

  const filterByKeyword = (e) => {
    e.preventDefault();
    applyFilter("keyword", keyword, selectedCountry, selectedStatus, selectedDate);
  }
  const filterByCountry = (e) => {
    setSelectedCountry(e.target.value);
    applyFilter("country", keyword, e.target.value, selectedStatus, selectedDate);
  }
  const filterByStatus = (e) => {
    setSelectedStatus(e.target.value)
    applyFilter("status", keyword, selectedCountry, e.target.value, selectedDate);
  }
  const filterByDate = (event) => {
    const d1 = Date.parse(event[0])
    const offset1 = new Date(Date.parse(event[0])).getTimezoneOffset() * 60000
    const d2 = Date.parse(event[1])
    const offset2 = new Date(Date.parse(event[1])).getTimezoneOffset() * 60000
    const xstartDate = d1 - (offset1)
    const xendDate = d2 - (offset2)
    if (xstartDate && (selectedDate.startDate !== xstartDate)) {
      if (xstartDate === xendDate) {
        setSelectedDate({ startDate: xstartDate, endDate: xendDate + 86340000 })
        applyFilter("date", keyword, selectedCountry, selectedStatus, { startDate: xstartDate, endDate: xendDate + 86340000 });
      }
      else {
        setSelectedDate({ startDate: xstartDate, endDate: xendDate + 86340000 })
        applyFilter("date", keyword, selectedCountry, selectedStatus, { startDate: xstartDate, endDate: xendDate + 86340000 });
      }
    }
  }
  const applyFilter = (filter, valueKeyword, valueCountry, valueStatus, valueDate) => {
    setLoading(true);
    setOffset(0)
    setActivePage(1)
    getAgencyOrders({
      limit: limit,
      offset: 0,
      ...(valueKeyword.replaceAll(/\s/g, '').length && { keyword: valueKeyword }),
      ...(valueCountry !== 0 && valueCountry !== "0" && { country: valueCountry }),
      ...(valueStatus !== 0 && valueStatus !== "0" && { status: valueStatus }),
      ...(valueDate.startDate && valueDate.endDate && { from: valueDate.startDate, to: valueDate.endDate })
    }).then(({ data }) => {
      if (data.status) {
        setOrderData(data.data.orders)
        setTotal(data.data.record_count)
      }
      else {
        showToast("error", "Request Failed", data.message)
      }
      setLoading(false)
    }).catch((err) => {
      showToast("error", "Request Failed", err.message)
      setLoading(false)
    });
  }
  const pageinateData = (page) => {
    if (activePage !== page) {
      setActivePage(page)
      setLoading(true)
      setOrderData([])
      getAgencyOrders({
        limit: limit,
        offset: limit * (page - 1),
        ...(keyword.replaceAll(/\s/g, '').length && { keyword: keyword }),
        ...(selectedCountry !== 0 && selectedCountry !== "0" && { country: selectedCountry }),
        ...(selectedStatus !== 0 && selectedStatus !== "0" && { status: selectedStatus }),
        ...(selectedDate.startDate && selectedDate.endDate && { from: selectedDate.startDate, to: selectedDate.endDate })
      }).then(({ data }) => {
        if (data.status) {
          setOrderData(data.data.orders)
          setOffset(limit * page)
        }
        else {
          showToast("error", "Request Failed", data.message)
        }
        setLoading(false)
      }).catch((err) => {
        showToast("error", "Request Failed", err.message)
        setLoading(false)
      });
    }
  }
  const clearDateRange = () => {
    datePickerRef.current.flatpickr.clear();
    setDateSelected(false);
    setSelectedDate({ startDate: 0, endDate: 0 });
    applyFilter("date", keyword, selectedCountry, selectedStatus, { startDate: 0, endDate: 0 });
  }

  const toggleFileUploader = () => {
    setScreen(1);
    setProgress(0);
    setProgressModal(false);
    setSelectedFiles([]);
    orderCount.current = 0;
    orderProcessingIndex.current = 0;
    setErrors({ selectedFiles: "", codeErrors: [] })
    setOrderModal(true);
    setApiLoading(false);
  }

  const validateXLSXFile = (records) => {
    const schema = ['string', 'string', 'string', 'code', 'relation', 'string'];
    const columns = ['Customer Name', 'Customer Email', 'Customer Phone', 'Discount Code', 'On who\'s behalf should it be performed?', 'Is Deceased?'];
    const output = records.map((item, key) => {
      let warnings = [...Array(10)].map((cell, index) => {
        switch (schema[index]) {
          case 'number':
            return isNaN(item[index]) || !item[index] || item[index] === "" ? { block: false, message: `There is an issue with the data on Row[${key + 1}] Column '${columns[index]}'` } : null
            break;
          case 'code':
            return !item[index] || item[index] === "" ? { block: true, message: `There is an issue with the data on Row[${key + 1}] Column '${columns[index]}'` } : null
            break;
          case 'relation':
            return !item[index] || item[index] === "" || (findRelationFromString(item[index]) === "" && item[index] !== "Don't Specify") ? { block: true, message: `There is an issue with the data on Row[${key + 1}] Column '${columns[index]}'` } : null
            break;
          case 'string':
            return !item[index] || item[index] === "" ? { block: false, message: `There is an issue with the data on Row[${key + 1}] Column '${columns[index]}'` } : null
            break;
          default:
            return null;
        }
      })
      return warnings.filter(item => item)
    }).filter(item => item.length).flat();
    return output;
  }

  function readXLSXFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const result = {};
          workbook.SheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            result[sheetName] = sheetData;
          });
          resolve(result);
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsBinaryString(file);
    });
  }

  const handleAcceptedFiles = files => {
    setErrors({ selectedFiles: "", codeErrors: [] })
    if (files[0].name.endsWith('.xlsx')) { // only allow .xlsx format
      if (files[0].size > 5000000) {
        setErrors({ selectedFiles: "File should be less than 5 MB", codeErrors: [] })
        setSelectedFiles([]);
      } else {
        setErrors({ selectedFiles: "", codeErrors: [] })
        files.map(file => Object.assign(file, { preview: URL.createObjectURL(file), formattedSize: formatBytes(file.size) }));
        setSelectedFiles(files)
        parseXLSXFiles(files[0])
      }
    }
    else {
      setErrors({ selectedFiles: "Please select a valid file with .xlsx extension", codeErrors: [] })
      setSelectedFiles([])
    }
  }

  const parseXLSXFiles = async (file) => {
    const fileReadData = await readXLSXFile(file);
    if (fileReadData && Object.keys(fileReadData)[0] && fileReadData[Object.keys(fileReadData)[0]] && fileReadData[Object.keys(fileReadData)[0]].length && fileReadData[Object.keys(fileReadData)[0]].length > 1 && fileReadData[Object.keys(fileReadData)[0]][0].length && fileReadData[Object.keys(fileReadData)[0]][0].length > 3) {
      setFileData(fileReadData[Object.keys(fileReadData)[0]])
    }
    else {
      setSelectedFiles([])
      setErrors({ selectedFiles: "Please select file with valid data. Use the sample sheet as an example", codeErrors: [] })
    }
  }

  const uploadOrders = () => {
    setApiLoading(true);
    let data = fileData.slice(1).filter((item) => item.length).map((d) => ({ name: d[0] ? d[0] : "", email: d[1], phone: d[2], discountCode: d[3], on_behalf: findRelationFromString(d[4]), isDeceased: d[5] === "Yes" ? true : false }))
    orderCount.current = data.length;
    orderProcessingIndex.current = 0;
    createUmrahOrder(data);
    setOrderModal(false);
    setProgressModal(true);
  }

  const createUmrahOrder = (items) => {
    if (orderProcessingIndex.current < orderCount.current) {
      let item = items[orderProcessingIndex.current];
      let obj = { service_code: "umrahalbadal", discountCode: item.discountCode };
      if (item.on_behalf || item.on_behalf !== "") {
        obj.on_behalf = item.on_behalf;
      }
      if (item.name && item.name.replaceAll(/\s/g, '').length) {
        let n = item.name;
        if (item.isDeceased) {
          n += " [marhoom]";
        }
        obj.name = n;
      }
      createOrder({ orders: [obj] }).then(({ data }) => {
        setErrors((prev) => ({ ...prev, codeErrors: [...prev.codeErrors, { status: data.status, code: data.statusCode, message: data.message }] }))
        if (data.status) {
          orderProcessingIndex.current += 1;
          if (orderProcessingIndex.current < orderCount.current) {
            createUmrahOrder(items);
            setProgress((prev) => (prev + ((1 / orderCount.current) * 100)));
          }
          else {
            finishOrderRequest();
          }
        }
        else {
          orderProcessingIndex.current += 1;
          if (orderProcessingIndex.current < orderCount.current) {
            createUmrahOrder(items);
            setProgress((prev) => (prev + ((1 / orderCount.current) * 100)));
          }
          else {
            finishOrderRequest();
          }
        }
      }).catch((e) => {
        setErrors((prev) => ({ ...prev, codeErrors: [...prev.codeErrors, { status: false, code: 500, message: e.message }] }))
        orderProcessingIndex.current += 1;
        if (orderProcessingIndex.current < orderCount.current) {
          createUmrahOrder(items);
          setProgress((prev) => (prev + ((1 / orderCount.current) * 100)));
        }
        else {
          finishOrderRequest();
        }
      })
    }
    else {
      finishOrderRequest();
    }
  }

  const finishOrderRequest = () => {
    setProgress(100);
    setUpdate((prev) => prev + 1)
  }

  // Create Order Manually
  const validateSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let flag = false;
    const error = request.map(() => ({ date: "", discountCode: "", email: "", phone: "" }));
    for (let i = 0; i < request.length; i++) {
      if (!request[i].discountCode || request[i].discountCode.length < 5) {
        error[i].discountCode = "Please provide a discount code for the order";
        flag = true;
      }
      if (!request[i].email || request[i].email.length < 5 || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(request[i].email)) {
        error[i].email = "Please provide a valid email address";
        flag = true;
      }
      if (!request[i].phone || request[i].phone.length < 5) {
        error[i].phone = "Please provide a phone number";
        flag = true;
      }
      if (request[i].isScheduled) {
        if (!request[i].date || !request[i].date.length) {
          error[i].date = "Please select a valid umrah date";
          flag = true;
        }
        let s = slots.find((item) => item.minTime === request[i].slot.split("-")[0]);
        let currentSaudiDate = momentT.tz("Asia/Riyadh");
        let selectedDate = momentT.tz("Asia/Riyadh").year(momentT(request[i].date).format("YYYY")).month(parseInt(momentT(request[i].date).format("M")) - 1).date(momentT(request[i].date).format("D")).hour(s.validation.replace("T", "").split(":")[0]).minute(s.validation.replace("T", "").split(":")[1]);
        if (!request[i].date || momentT(selectedDate).isBefore(momentT(currentSaudiDate))) {
          error[i].date = "Minimum date allowed is " + momentT.tz("Asia/Riyadh").format("DD-MM-YYYY hh:mm A");
          flag = true;
        }
      }
    }
    setOrderErrors(error);
    if (!flag) {
      const dataObject = request.map((item) => {
        let obj = { service_code: item.service_code, discountCode: item.discountCode };
        if (item.isScheduled) {
          obj.seeker_umrah_date_time = momentT.utc(item.date).valueOf();
          obj.start_time = item.slot.split("-")[0];
          obj.end_time = item.slot.split("-")[1];
        }
        if (item.on_behalf) {
          obj.on_behalf = item.on_behalf;
        }
        if (item.name && item.name.replaceAll(/\s/g, '').length) {
          let n = item.name;
          if (item.isDeceased) {
            if (item.on_behalf !== "relationtype1") {
              n = " المرحوم/ة " + " " + n;
            }
          }
          obj.name = n;
          obj.email = item.email;
          obj.phone = item.phone;
        }
        return obj;
      })
      // console.log("order data", dataObject)
      createOrder({ orders: dataObject }).then(({ data }) => {
        if (data.status) {
          showToast("success", "Order Created", "A new order has been created and is pending approval from admin")
          setCreateOrderModal(false)
          setOrderUpdate(prev => prev + 1)
        }
        else {
          showToast("error", "Request Failed", data.message)
          setLoading(false)
        }
      }).catch((e) => {
        showToast("error", "Request Failed", e.message)
        setLoading(false)
      })
    }
    else {
      setLoading(false);
    }
  }

  const showSaudiDatetime = (date, time) => {
    if (date && time) {
      let s = slots.find((item) => item.minTime === time.split("-")[0]);
      return `${momentT(date).format("DD-MMM-YYYY")} ${s.start} to ${momentT(date).format("DD-MMM-YYYY")} ${s.end}`;
    }
    return "";
  }

  const createRow = (e) => {
    const newValue = parseInt(e.target.value, 10); // Convert input value to integer

    if (newValue) {
      if (newValue > orderQuantity) {
        setRequest((prev) => [...prev, { service_code: "umrahalbadal", on_behalf: "", name: "", email: "", phone: "", discountCode: "", slot: "1705093200000-1705103999000", date: momentT().format("YYYY-MM-DD"), isScheduled: true, isDeceased: false }]);
        setOrderErrors((prev) => [...prev, { name: "", email: "", phone: "", discountCode: "" }]);
      } else if (newValue < orderQuantity && newValue >= 1) {
        setRequest((prev) => prev.slice(0, newValue))
        setOrderErrors((prev) => prev.slice(0, newValue))
      }
      setOrderQuantity(newValue);
    } else {
      setOrderQuantity(1);
    }

  }
  // Prevent typing in the input field
  const preventTyping = (e) => {
    e.preventDefault();
  };

  const resetOrderModal = () => {
    setRequest([{ service_code: "umrahalbadal", on_behalf: "", name: "", email: "", phone: "", discountCode: "", slot: "1705093200000-1705103999000", date: momentT().format("YYYY-MM-DD"), isScheduled: true, isDeceased: false }]);
    setOrderQuantity(1)
    setCreateOrderModal(false)
  }
  // Drop down code
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  function toggleDropdownn() {
    setDropDownOpen(!dropDownOpen);
  }
  const handleOptionClick = () => {
    setDropDownOpen(false);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropDownOpen(false);
    }
  };
  // dropdown ends


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="filter-btn-container d-flex justify-between align-items-center mb-2">
                  <div className="filter-container">
                    <div md={3} className="mb-3">
                      <form onSubmit={filterByKeyword}>
                        <label htmlFor="orderSearch" className="d-block">{_T("Search Orders")}</label>
                        <InputGroup >
                          <input id="orderSearch" className="rounded border-left-0 form-control admin-search" style={{ marginLeft: "-4px", zIndex: "0" }} type="search" value={keyword} onChange={(e) => { setKeyword(e.target.value) }} placeholder={_T("Search by Order ID or Seeker Name")} />
                          <button type="submit" className="admin-btn-search" style={{ zIndex: "1" }}><i className="ri-search-2-line search-icon"></i></button>
                        </InputGroup>
                      </form>
                    </div>
                    <div md={2} className="mb-3">
                      <label htmlFor="selectedCountry" className="d-block">{_T("Filter by Country")}</label>
                      <select className="form-select admin-select" id="selectedCountry" value={selectedCountry} onChange={filterByCountry}>
                        <option value={0}>{_T("Show All")}</option>
                        {Object.entries(order_countries).map((country, key) => {
                          return (
                            <option value={country[0]} key={key}>{country[1]}</option>
                          )
                        })}
                      </select>
                    </div>
                    <div md={2} className="mb-3">
                      <label htmlFor="selectedStatus" className="d-block">{_T("Filter by Status")}</label>
                      <select className="form-select admin-select" id="selectedStatus" value={selectedStatus} onChange={filterByStatus}>
                        <option value={0}>{_T("Show All")}</option>
                        <option value={1}>{_T("Show Pending Orders")}</option>
                        <option value={2}>{_T("Show Paid Orders")}</option>
                        <option value={3}>{_T("Show Waiting for Performer Orders")}</option>
                        <option value={4}>{_T("Show Waiting for Seeker Orders")}</option>
                        <option value={5}>{_T("Show Accepted Orders")}</option>
                        <option value={6}>{_T("Show Rejected Orders")}</option>
                        <option value={7}>{_T("Show Cancelled Orders")}</option>
                        <option value={8}>{_T("Show In-Progress Orders")}</option>
                        <option value={9}>{_T("Show Completed Orders")}</option>
                        <option value={10}>{_T("Show Refunded Orders")}</option>
                      </select>
                    </div>
                    <div md={3} className="mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <label htmlFor="selectedDate" className="d-block">{_T("Filter by Date")}</label>
                        {
                          dateSelected ?
                            <span className="text-danger cursor-pointer" onClick={() => { clearDateRange(); }}>{_T("Clear")}</span> : <></>
                        }
                      </div>
                      <Flatpickr ref={datePickerRef} id="selectedDate" className="form-control d-block" style={{ backgroundColor: "#F2E7D5", border: "none" }} placeholder={_T("Select Date Range")} options={{ mode: "range", dateFormat: "d-m-Y", maxDate: moment().toISOString() }} onClose={filterByDate} />
                    </div>
                  </div>
                  <div className="umrah-dropdown" ref={dropdownRef}>
                    <button className="umrah-dropdown-toggle" onClick={toggleDropdownn}>{_T("Create Umrah Order")}</button>

                    {dropDownOpen && (
                      <div className="umrah-dropdown-content">
                        <button onClick={() => { handleOptionClick(); toggleFileUploader() }}>{_T("Upload File")}</button>
                        <button onClick={() => { handleOptionClick(); setCreateOrderModal(true); }}>{_T("Create Manually")}</button>
                      </div>
                    )}
                  </div>
                </div>
                <Row>
                  <div className="table-container">
                    <table responsive="true" className="table-bordered" style={{ direction: "ltr" }}>
                      <thead>
                        <tr>
                          <th className="fs-12 text-gray-500">{_T("Order ID")}</th>
                          <th className="fs-12 text-gray-500">{_T("Seeker")}</th>
                          <th className="fs-12 text-gray-500">{_T("Country")}</th>
                          <th className="fs-12 text-gray-500">{_T("Code")}</th>
                          <th className="text-center fs-12 text-gray-500">{_T("Payment")}</th>
                          <th className="text-center fs-12 text-gray-500">{_T("Status")}</th>
                          <th className="fs-12 text-gray-500">{_T("Order Date")}</th>
                          <th className="fs-12 text-gray-500">{_T("Assigned")}</th>
                          <th className="fs-12 text-gray-500">{_T("Service Start")}</th>
                          <th className="fs-12 text-gray-500">{_T("Service")}</th>
                          <th className="fs-12 text-gray-500 text-center">{_T("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          loading ?
                            [...Array(30)].map((a, i) => (
                              <tr key={i}>
                                <td className="fs-12 text-gray-500"><Skeleton height={12} width={125} /></td>
                                <td className="fs-12 text-gray-500"><Skeleton height={12} width={125} /></td>
                                <td className="fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                                <td className="fs-12 text-gray-500"><Skeleton height={12} width={50} /></td>
                                <td className="text-center fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                                <td className="text-center fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                                <td className="fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                                <td className="fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                                <td className="fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                                <td className="fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                                <td className="fs-12 text-gray-500 text-center"><Skeleton height={12} width={90} /></td>
                              </tr>
                            )) :
                            orderData.length === 0 ?
                              <tr><td colSpan="10" className="text-center fs-12 fw-600"><span className="d-flex align-items-center justify-content-center"><i className="ri-error-warning-line text-danger fs-22 fw-300 me-2"></i> Tawkeel currently has no order records.</span></td></tr> :
                              orderData.map((row, i) => (
                                <tr key={i}>
                                  <td className="fs-12 text-gray-500 text-uppercase">{row.generated_id}</td>
                                  <td className="fs-12 text-gray-500 text-capitalize">{row.seeker_name.length > 20 ? row.seeker_name.slice(0, 20) + "..." : row.seeker_name}</td>
                                  <td className="fs-12 text-gray-500">{order_countries[row.seeker_country] ? order_countries[row.seeker_country] : row.seeker_country}</td>
                                  <td className="fs-12 text-gray-500">{row.order_detail && row.order_detail.discount_code && row.order_detail.discount_code.length ? <span className="badge-success badge-soft-success font-size-12 badge badge-pill">{row.order_detail.discount_code}</span> : "N/A"}</td>
                                  <td className="text-center fs-12 text-gray-500"><span className={`badge-${row.payment_status ? "success" : "danger"} badge-soft-${row.payment_status ? "success" : "danger"} font-size-12 badge badge-pill`}>{row.payment_status ? _T("Paid") : _T("Unpaid")}</span></td>
                                  <td className="text-center fs-12 text-gray-500">{_T(OrderStatusEnum[row.order_status].html)}</td>
                                  <td className="fs-12 text-gray-500">{moment(row.timestamp).format("D/M/YYYY")}</td>
                                  <td className="fs-12 text-gray-500">{row.performer_assigned_time ? moment(row.performer_assigned_time).format("D/M/YYYY") : <span className={'badge-danger badge-soft-danger font-size-12 badge badge-pill'}>{_T("Unassigned")}</span>}</td>
                                  <td className="fs-12 text-gray-500">{row.performers.filter((e) => e.performer_status === 5).length ? moment(row.performers.filter((e) => e.performer_status === 5)[0].performer_timestamp).format("D/M/YYYY") : <span className={'badge-danger badge-soft-danger font-size-12 badge badge-pill'}>{_T("Unassigned")}</span>}</td>
                                  <td className="fs-12 text-gray-500">{row.service_name}</td>
                                  <td className="fs-12 text-gray-500 text-center"><a class="btn btn-primary" style={{height: "40px", padding: "0px 25px"}} href="https://wa.me/+9668002442203" target="_blank"><i className="ri-customer-service-2-fill"></i> <div>&nbsp;</div> {_T("Support")}</a></td>
                                </tr>
                              ))
                        }
                      </tbody>
                    </table>
                    <Row className="align-items-center pagination-container">
                      <Col md="6">
                        {loading ? <Skeleton height={14} width={200} /> : <span className="fs-14 fw-600 text-dark align-middle">{_T("Showing")} {(limit * (activePage - 1)) + 1} to {(limit * (activePage - 1)) + orderData.length} {_T("of")} {total} {_T("Results")}</span>}
                      </Col>
                      <Col md="6" className="d-flex justify-content-end">
                        {
                          loading ?
                            <Skeleton height={32} width={250} /> :
                            <nav>
                              <Pagination activePage={activePage} itemsCountPerPage={limit} totalItemsCount={total} pageRangeDisplayed={3} onChange={pageinateData} itemClass={"page-item"} linkClass={"page-link"} firstPageText={_T("First")} lastPageText={_T("Last")} prevPageText={_T("Previous")} nextPageText={_T("Next")} innerClass="pagination m-0" />
                            </nav>
                        }
                      </Col>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={orderModal} onClose={() => { setOrderModal(false) }} size={screen === 1 ? "md" : "xl"} centered={true} title={_T("Create Orders")} modalSize={screen === 2 ? "xLarge" : ""}>
          <div>
            {
              screen === 1 ?
                <React.Fragment>
                  <Row className=' mb-3'>
                    <Col md={12} className="mb-3">
                      <div className="d-flex justify-between align-items-center">
                        <h4 className="mb-2 mt-0">{_T("Upload File")}: ({_T("Max")}: 5MB, {_T("Supports")}: .xlsx)<span className='text-danger'>*</span></h4>
                        <small className="mb-2 d-block download-sample-sheet">{_T("Download")} <a href={sampleSheet} download> {_T("sample sheet")}</a></small>
                      </div>
                      {
                        selectedFiles.length === 0 ?
                          <Dropzone multiple={false} onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)} >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone cursor-pointer">
                                <div className="dz-message needsclick" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <div className="mb-3"><i className="display-4 text-muted ri-upload-cloud-2-line"></i></div>
                                  <h6 className="text-muted m-0">{_T("Drop File Here")} ({_T("Max")}: 5MB, {_T("Supports")}: .xlsx)</h6>
                                </div>
                              </div>
                            )}
                          </Dropzone> :
                          <div className="dropzone-previews mt-3" id="file-previews">
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col>
                                        <Link to="#" className="text-muted fw-bold">{f.name}</Link>
                                        <Row>
                                          <Col><p className="mb-0"><strong>{f.formattedSize}</strong></p></Col>
                                          <Col className="text-right"><button className="clear-btn m-0" onClick={() => { setSelectedFiles([]); setErrors({ selectedFiles: "", codeErrors: [] }) }}>{_T("Clear File")}</button></Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                      }
                      {errors.selectedFiles ? <p className="my-2 d-flex align-items-center justify-content-center text-danger"><i className='ri-error-warning-line fs-5 mr-2'></i>{errors.selectedFiles}</p> : null}
                    </Col>
                  </Row>
                  <Row className="text-right mt-3">
                    <Col>
                      <button className="btn btn-outline-primary mr-2" onClick={() => { setOrderModal(false) }}>{_T("Close")}</button>
                      <button className="btn btn-primary" disabled={!fileData} onClick={() => { setScreen(2) }}>{_T("Preview")}</button>
                    </Col>
                  </Row>
                </React.Fragment>
                :
                <React.Fragment>
                  <Row>
                    <Col md={12}>
                      <div className='table-responsive' style={{ maxHeight: "280px", overflow: "auto" }}>
                        <table className='m-0 table table-bordered table-hover' >
                          <thead>
                            <tr>
                              <th className='fs-12 px-1 text-center sticky-top bg-white'>#</th>
                              <th className='fs-12 px-1 sticky-top bg-white'>{_T("Customer Name")}</th>
                              <th className='fs-12 px-1 sticky-top bg-white'>{_T("Customer Email")}</th>
                              <th className='fs-12 px-1 sticky-top bg-white'>{_T("Customer Phone")}</th>
                              <th className='fs-12 px-1 sticky-top bg-white'>{_T("Discount Code")}</th>
                              <th className='fs-12 px-1 sticky-top bg-white'>{_T("On who's behalf should it be performed?")}</th>
                              <th className='fs-12 px-1 sticky-top bg-white'>{_T("Is Deceased?")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              fileData.slice(1).filter((item) => item.length).map((item, key) => {
                                return <tr key={"row" + key}>{
                                  <React.Fragment>
                                    <td className='fs-12 px-1 text-center'>{key + 1}</td>
                                    {[...Array(6)].map((cell, index) => {
                                      return <td className={`fs-12 p-1`} key={"cell" + index}>{item[index] ? item[index] : <i className={`ri-error-warning-line text-${index === 1 || index === 2 ? "danger" : "warning"} fs-16`}></i>}</td>
                                    })}
                                  </React.Fragment>
                                }</tr>
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    {
                      validateXLSXFile(fileData.slice(1).filter((item) => item.length)).length ?
                        <Col md={12} className='text-center mt-3'>
                          <UncontrolledAccordion defaultOpen="1">
                            <AccordionItem>
                              {/* <AccordionHeader targetId="1">
                                <p className='d-flex align-items-center justify-content-start m-0'><i className="ri-error-warning-line text-primary fs-22 fw-300 me-2"></i> There might be some issues with the file you have uploaded. Click here to see more information</p>
                              </AccordionHeader> */}
                              <AccordionBody accordionId="1">
                                {
                                  validateXLSXFile(fileData.slice(1).filter((item) => item.length)).map((e, index) => {
                                    return <span key={index} className="d-flex align-items-center justify-content-start"><i className={`ri-error-warning-line text-${e.block ? "danger" : "warning"} fs-22 fw-300 me-2`}></i> {e.message}</span>
                                  })
                                }
                              </AccordionBody>
                            </AccordionItem>
                          </UncontrolledAccordion>
                        </Col> : null
                    }
                  </Row>
                  <Row className="text-right mt-3">
                    <Col>
                      <button className="btn btn-outline-primary mr-2" onClick={() => { setScreen(1) }}>{_T("Go Back")}</button>
                      {
                        validateXLSXFile(fileData.slice(1).filter((item) => item.length)).filter((item) => item.block).length ?
                          null : <button className="btn btn-primary" disabled={apiLoading} onClick={uploadOrders}>{`${apiLoading ? _T("Please Wait..") : _T("Proceed with")} ${apiLoading ? "" : fileData.slice(1).filter((item) => item.length).length + ' Records'}`}</button>
                      }
                    </Col>
                  </Row>
                </React.Fragment>
            }
          </div>
        </Modal>
        <Modal isOpen={progressModal} backdrop={"static"} centered={true}>
          <ModalBody>
            <div className="d-flex justify-between align-items-center">
              <h2 className="fs-16 fw-500 text-dark m-0">{_T("Creating Umrah Orders")}</h2>
              {
                progress === 100 ? <span className="text-dark cursor-pointer" onClick={() => { setProgressModal(false); }}><i className="ri-close-line fs-18"></i></span> : <i className="ri-loader-line align-middle animate-spin fs-18"></i>
              }
            </div>
            <Progress animated className="my-3" color={errors.codeErrors.filter(c => !c.status).length && progress === 100 ? "danger" : "success"} value={progress} />
            {
              progress === 100 ?
                errors.codeErrors.filter(c => !c.status).length ?
                  <React.Fragment>
                    <p className="text-danger fs-14 mt-3 mb-0">{errors.codeErrors.filter(c => !c.status).length} {_T("order(s) could not be created because of the following reasons,")}</p>
                    <Table>
                      <thead><tr><th className="text-center fs-12 text-gray-500">{_T("Order No.")}</th><th className="fs-12 text-gray-500">{_T("Reason")}</th><th className="fs-12 text-gray-500 text-center">{_T("Error Code")}</th></tr></thead>
                      <tbody>
                        {errors.codeErrors.map((c, i) => !c.status ? <tr key={i}><td className="text-center fs-12 text-gray-500">{i + 1}</td><td className="fs-12 text-gray-500">{c.message ? c.message : c.code === 500 ? _T("Server Error") : _T("The discount code was not valid")}</td><td className="fs-12 text-gray-500 text-center">{c.code}</td></tr> : null)}
                      </tbody>
                    </Table>
                  </React.Fragment> :
                  <p className="text-success fs-14 mt-3 mb-0">{_T("All the orders were successfully created.")}</p> :
                <p className="fs-14 text-dark fw-500 text-center">{progress === 100 ? _T("Umrah Orders Created") : _T("Processing Umrah Orders")} ({progress.toFixed()}%)</p>
            }
          </ModalBody>
        </Modal>

        {/* Create Order Modal */}
        <Modal isOpen={createOrderModal} backdrop={"static"} size={"xl"} centered={true} modalSize="xLarge" onClose={() => { resetOrderModal() }}>
          <ModalBody>
            <div className="create-order-container">
              <div className="section-left">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="9">
                        <h4 className="card-title">{_T("Create New Order")}</h4>
                        <p className="card-title-desc">{_T("Please use the following options to create a new order")}</p>
                      </Col>
                      <Col md="3" className={"mb-4 mt-4 styled-number-input"}>
                        {/* <button className="btn btn-outline-primary" type="button" disabled={loading} onClick={createRow}>Add Another Order</button> */}
                        <label htmlFor="noOrders">{_T("No of Orders")}</label>
                        <input type="number" id="noOrders" className="custom-number-input" value={orderQuantity} disabled={loading} onChange={createRow} onKeyDown={preventTyping} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <form onSubmit={validateSubmit}>
                          {
                            request.map((item, index) => {
                              return <Row key={`row-${index}`} className="single-order-form mb-4">
                                <Col md={4} className={"mb-3"}>
                                  <h3 className="mb-2 mt-2 info-heading" style={{color: "#C29F60"}}>{_T("Order No.")} {index + 1}</h3>
                                  <label htmlFor={`seekerName-${index}`}>{_T("Customer Name")}</label>
                                  <input id={`seekerName-${index}`} placeholder={_T("Enter Customer's Name")} className="form-control" value={item.name} onChange={(e) => { setRequest((prev) => prev.map((x, i) => i === index ? { ...x, name: e.target.value.slice(0, 50) } : x)) }} />
                                  {orderErrors[index].name ? <p className="fs-12 fw-500 text-danger mt-1 mb-0">{orderErrors[index].name}</p> : null}
                                </Col>
                                <Col md={4} className={"mb-3"}>
                                  <label htmlFor={`seekerEmail-${index}`}>{_T("Customer Email")}</label>
                                  <input id={`seekerEmail-${index}`} placeholder={_T("Enter Customer's Email")} className="form-control" value={item.email} onChange={(e) => { setRequest((prev) => prev.map((x, i) => i === index ? { ...x, email: e.target.value.slice(0, 100) } : x)) }} />
                                  {orderErrors[index].email ? <p className="fs-12 fw-500 text-danger mt-1 mb-0">{orderErrors[index].email}</p> : null}
                                </Col>
                                <Col md={4} className={"mb-3"}>
                                  <label htmlFor={`seekerPhone-${index}`}>{_T("Customer Phone Number")}</label>
                                  <input id={`seekerPhone-${index}`} placeholder={_T("Enter Customer's Phone Number")} className="form-control" value={item.phone} onChange={(e) => { setRequest((prev) => prev.map((x, i) => i === index ? { ...x, phone: e.target.value.replace(/[^0-9]/g, '').length ? parseInt(e.target.value.replace(/[^0-9]/g, '').slice(0, 20)) : 0 } : x)) }} />
                                  {orderErrors[index].phone ? <p className="fs-12 fw-500 text-danger mt-1 mb-0">{orderErrors[index].phone}</p> : null}
                                </Col>
                                <Col md={4} className={"mb-3"}>
                                  <label htmlFor={`relationship-${index}`}>{_T("On who's behalf should it be performed?")} <span className="text-danger">*</span></label>
                                  <select id={`relationship-${index}`} className="form-select" value={item.on_behalf} onChange={(e) => { setRequest((prev) => prev.map((x, i) => i === index ? { ...x, on_behalf: e.target.value } : x)) }}>
                                    <option value={""}>{_T("Don't Specify")}</option>
                                    {
                                      Object.keys(relationtypes).map((k, i) => {
                                        return <option key={i} value={k}>{relationtypes[k]}</option>
                                      })
                                    }
                                  </select>
                                </Col>
                                <Col md={4} className={"mb-3"}>
                                  <label htmlFor={`discountCode-${index}`}>{_T("Discount Code")} <span className="text-danger">*</span></label>
                                  <input id={`discountCode-${index}`} placeholder={_T("Enter Discount Code")} className="form-control" value={item.discountCode} onChange={(e) => { setRequest((prev) => prev.map((x, i) => i === index ? { ...x, discountCode: e.target.value.slice(0, 15) } : x)) }} />
                                  {orderErrors[index].discountCode ? <p className="fs-12 fw-500 text-danger mt-1 mb-0">{orderErrors[index].discountCode}</p> : null}
                                </Col>
                                {/* <Col md={4} className={"mb-3"}>
                                  <label htmlFor={`isScheduled-${index}`} className="mb-0">Is Scheduled?</label>
                                  <small className="d-block mb-2">Do you need to set a start date for the umrah?</small>
                                  <div className="form-check form-switch mb-3" dir="ltr">
                                    <input id={`isScheduled-${index}`} type="checkbox" className="form-check-input form-check-input" checked={item.isScheduled} onChange={(e) => { setRequest((prev) => prev.map((x, i) => i === index ? { ...x, isScheduled: e.target.checked } : x)) }} />
                                    <label htmlFor={`isScheduled-${index}`} className="form-check-label form-label">{item.isScheduled ? "Yes" : "No"}</label>
                                  </div>
                                </Col> */}
                                {
                                  // item.isScheduled ?
                                  <React.Fragment>
                                    <Col md={4} className={"mb-3"}>
                                      <label htmlFor={`date-${index}`}>{_T("Umrah Date")} <span className="text-danger">*</span></label>
                                      <input type="date" className="form-control" value={item.date} onChange={(e) => { setRequest((prev) => prev.map((x, i) => i === index ? { ...x, date: e.target.value } : x)) }} />
                                      {orderErrors[index].date ? <p className="fs-12 fw-500 text-danger mt-1 mb-0">{orderErrors[index].date}</p> : null}
                                    </Col>
                                    <Col md={4} className={"mb-3"}>
                                      <label htmlFor={`slot-${index}`}>{_T("Umrah Slot")} <span className="text-danger">*</span> ({_T("KSA timezone")})</label>
                                      <select id={`slot-${index}`} className="form-select" value={item.slot} onChange={(e) => { setRequest((prev) => prev.map((x, i) => i === index ? { ...x, slot: e.target.value } : x)) }} >
                                        {slots.map((s, i) => <option key={i} value={s.minTime + "-" + s.maxTime}>{s.displayTime}</option>)}
                                      </select>
                                      <small className="text-muted">{showSaudiDatetime(item.date, item.slot)}</small>
                                    </Col>
                                  </React.Fragment>
                                  // : null
                                }
                                {
                                  item.name && item.name.replaceAll(/\s/g, '').length ?
                                    item.on_behalf !== "relationtype1" ?
                                      <Col md={4} className={"mb-3"}>
                                        <label htmlFor={`isDeceased-${index}`} className="mb-0">{_T("Is Deceased?")}</label>
                                        <small className="d-block mb-2">{_T("Is the person deceased?")}</small>
                                        {/* <div className="form-check form-switch mb-3" dir="ltr">
                                          <input id={`isDeceased-${index}`} type="checkbox" className="form-check-input form-check-input" checked={item.isDeceased} onChange={(e) => { setRequest((prev) => prev.map((x, i) => i === index ? { ...x, isDeceased: e.target.checked } : x)) }} />
                                          <label htmlFor={`isDeceased-${index}`} className="form-check-label form-label">{item.isDeceased ? "Deceased" : "Alive"}</label>
                                        </div> */}
                                        <div className="d-flex justify-start align-item-center">
                                          <div className="form-check mb-3 mr-2">
                                            <input name={`isDeceased-${index}`} id={`isDeceasedNo-${index}`} type="radio" className="form-check-input form-check-input" checked={item.isDeceased === false} onChange={(e) => { if (e.target.checked) setRequest((prev) => prev.map((x, i) => i === index ? { ...x, isDeceased: false } : x)) }} />
                                            <label htmlFor={`isDeceasedNo-${index}`} className={`form-check-label form-label`}>{_T("No")}</label>
                                          </div>
                                          <div className="form-check mb-3">
                                            <input name={`isDeceased-${index}`} id={`isDeceasedYes-${index}`} type="radio" className="form-check-input form-check-input" checked={item.isDeceased === true} onChange={(e) => { if (e.target.checked) setRequest((prev) => prev.map((x, i) => i === index ? { ...x, isDeceased: true } : x)) }} />
                                            <label htmlFor={`isDeceasedYes-${index}`} className={`form-check-label form-label`}>{_T("Yes")}</label>
                                          </div>
                                        </div>
                                      </Col> : null
                                    : null
                                }
                                {
                                  // request.length === index + 1 && request.length > 1 ?
                                  //   <Col md={12} className={"mt-4 text-right"}>
                                  //     <p onClick={() => { loading ? console.log("") : removeRow(index) }} className="d-inline-block text-danger m-0 fs-14 fw-600 cursor-pointer">Remove Row</p>
                                  //   </Col> : null
                                }
                                {
                                  // request.length > index + 1 ?
                                  //   <Col md={12} className={"mt-3 mb-4"}>
                                  //     <hr className="m-0 p-0" />
                                  //   </Col> : null
                                }
                              </Row>
                            })
                          }
                          <Row className={"mt-4"}>
                            <Col md={12} className={""}>
                              <button className="btn btn-primary" disabled={loading} type="submit">{_T("Create Order")}</button>
                            </Col>
                          </Row>
                        </form>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
              <div className="section-right">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col md={12}>
                        <h5 className="mb-0 d-flex align-items-center title">
                          {_T("Tawkeel Support")}
                          <i className="ri-information-line text-primary fs-20 ms-2"></i>
                        </h5>
                        <p className="mb-0 font-size-12 title-desc">
                          {_T("This section provides information about how to create a new order")}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <ul className="mb-0">
                          <li className="mb-1">
                            <h6 className="mb-1 info-heading">{_T("Customer Name")}</h6>
                            <p className="mb-0 font-size-12 info-desc">
                              {_T("This is the name of the person on whose behalf the Umrah will be performed.")}
                            </p>
                          </li>
                          <li className="mb-1">
                            <h6 className="mb-1 info-heading">{_T("On who's behalf should it be performed?")}</h6>
                            <p className="mb-0 font-size-12 info-desc">
                              {_T("This is the relation of the user with the person to whom the user wants to dedicate the Umrah Order.")}
                            </p>
                          </li>
                          <li className="mb-1">
                            <h6 className="mb-1 info-heading">{_T("Discount Code")}</h6>
                            <p className="mb-0 font-size-12 info-desc">
                              {_T("This is a discount code from your agency that should be used to create this order.")}
                            </p>
                          </li>
                          <li className="mb-1">
                            <h6 className="mb-1 info-heading">{_T("Umrah Slot")}</h6>
                            <p className="mb-0 font-size-12 info-desc">
                              {_T("If umrah has to be Scheduled, the umrah slot will be used to perform the umrah within the defined time as per Kingdom of Saudi Arabia Timezone")}.
                            </p>
                          </li>
                          <li className="mb-1">
                            <h6 className="mb-1 info-heading">{_T("Umrah Date")}</h6>
                            <p className="mb-0 font-size-12 info-desc">
                              {_T("If umrah has to be Scheduled for a specific date, use this field to select an umrah date")}.
                            </p>
                          </li>
                          <li className="mb-1">
                            <h6 className="mb-1 info-heading">{_T("Add Another Order")}</h6>
                            <p className="mb-0 font-size-12 info-desc">
                              {_T("The 'Add Another Order' button helps to create multiple orders at once. Press the button to add details for another order.")}
                            </p>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default OrderManagement;