import { Navigate } from "react-router-dom";

import Home from '../pages/Home/index'
import Campaign from '../pages/Home/Campaign'
import QuickRegister from '../pages/QuickRegister/index'
import HelpCenter from '../pages/HelpCenter/index'
import PerformerHelpCenter from '../pages/HelpCenter/PerformerHelpCenter'
import SeekerHelpCenter from '../pages/HelpCenter/SeekerHelpCenter'
import TermsAndConditions from '../pages/TermsAndConditions/index'
import PerformerTermsAndConditions from '../pages/TermsAndConditions/PerformerTermsAndConditions'
import SeekerTermsAndConditions from '../pages/TermsAndConditions/SeekerTermsAndConditions'
import PrivacyPolicy from '../pages/PrivacyPolicy/index'
import PerformerPrivacyPolicy from '../pages/PrivacyPolicy/PerformerPrivacyPolicy'
import SeekerPrivacyPolicy from '../pages/PrivacyPolicy/SeekerPrivacyPolicy'
import ResetPassword from '../pages/ResetPassword'
import ResetAgencyPassword from '../pages/Authentication/ResetPassword'
import FAQ from '../pages/FAQ/Index'
import Agents from '../pages/Agents'
import Contract from '../pages/Contract'
import AgencyRegister from '../pages/AgencyRegister'
import News from '../pages/News/Index'
import UmraAlBadal from '../pages/UmraAlBadal'
import AgentAgency from '../pages/AgentAgency/Hero'
import Login from "../pages/Authentication/Login";
import AgencyDashboard from "../pages/AgencyDashboard";
import ForgotPassword from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import MyProfile from "../pages/AgencyDashboard/MyProfile";

export const DefaultRoute = (props) => {
	return <Navigate to="/" />;
};

const publicRoutes = [
    { path: "/", element: Home, language: "ar" },
    { path: "/en", element: Home, language: "en" },
    { path: "/campaign", element: Campaign, language: "ar" },
    { path: "/quick-register", element: QuickRegister, language: "ar" },
    { path: "/en/quick-register", element: QuickRegister, language: "en" },
    { path: "/agency-register", element: AgencyRegister, language: "ar" },
    { path: "/en/agency-register", element: AgencyRegister, language: "en" },
    { path: "/agents", element: Agents, language: "ar" },
    { path: "/en/agents", element: Agents, language: "en" },
    { path: "/news", element: News, language: "ar" },
    { path: "/en/news", element: News, language: "en" },
    { path: "/help-center", element: HelpCenter, language: "ar" },
    { path: "/en/help-center", element: HelpCenter, language: "en" },
    { path: "/help-center/performer", element: PerformerHelpCenter, language: "ar" },
    { path: "/help-center/seeker", element: SeekerHelpCenter, language: "ar" },
    { path: "/en/help-center/performer", element: PerformerHelpCenter, language: "en" },
    { path: "/en/help-center/seeker", element: SeekerHelpCenter, language: "en" },
    { path: "/terms-and-conditions", element: TermsAndConditions, language: "ar" },
    { path: "/ar/terms-and-conditions", element: TermsAndConditions, language: "ar" },
    { path: "/en/terms-and-conditions", element: TermsAndConditions, language: "en" },
    { path: "/terms-and-conditions/performer", element: PerformerTermsAndConditions, language: "ar" },
    { path: "/terms-and-conditions/seeker", element: SeekerTermsAndConditions, language: "ar" },
    { path: "/ar/terms-and-conditions/performer", element: PerformerTermsAndConditions, language: "ar" },
    { path: "/ar/terms-and-conditions/seeker", element: SeekerTermsAndConditions, language: "ar" },
    { path: "/en/terms-and-conditions/performer", element: PerformerTermsAndConditions, language: "en" },
    { path: "/en/terms-and-conditions/seeker", element: SeekerTermsAndConditions, language: "en" },
    { path: "/frequently-asked-questions", element: FAQ, language: "ar" },
    { path: "/en/frequently-asked-questions", element: FAQ, language: "en" },
    { path: "/privacy-policy", element: PrivacyPolicy, language: "ar" },
    { path: "/ar/privacy-policy", element: PrivacyPolicy, language: "ar" },
    { path: "/en/privacy-policy", element: PrivacyPolicy, language: "en" },
    { path: "/privacy-policy/performer", element: PerformerPrivacyPolicy, language: "ar" },
    { path: "/privacy-policy/seeker", element: SeekerPrivacyPolicy, language: "ar" },
    { path: "/ar/privacy-policy/performer", element: PerformerPrivacyPolicy, language: "ar" },
    { path: "/ar/privacy-policy/seeker", element: SeekerPrivacyPolicy, language: "ar" },
    { path: "/en/privacy-policy/performer", element: PerformerPrivacyPolicy, language: "en" },
    { path: "/en/privacy-policy/seeker", element: SeekerPrivacyPolicy, language: "en" },
    { path: "/reset-user-password/:token", element: ResetPassword, language: "en" },
    { path: "/contract/:agent/:contract", element: Contract, language: "en" },
    { path: "/en/umrah-al-badal", element: UmraAlBadal, language: "en" },
    { path: "/umrah-al-badal", element: UmraAlBadal, language: "ar" },
    { path: "/agent-agency", element: AgentAgency, language: "en" },
    { path: "/login", element: Login, language: "en" },
    { path: "/logout", element: Logout, language: "en" },
    { path: "/forgot-password", element: ForgotPassword, language: "en" },
    { path: "/reset-password/:token", element: ResetAgencyPassword, language: "en" },
]

const privateRoutes = [
    { path: "/agent-dashboard", element: AgencyDashboard, language: "ar" },
    { path: "/order-management", element: AgencyDashboard, language: "ar" },
    { path: "/invoices", element: AgencyDashboard, language: "ar" },
    { path: "/purchase-code", element: AgencyDashboard, language: "ar" },
    { path: "/payments", element: AgencyDashboard, language: "ar" },
    { path: "/report-analysis", element: AgencyDashboard, language: "ar" },
    { path: "/agency-profile", element: AgencyDashboard, language: "ar" },
    { path: "/en/agent-dashboard", element: AgencyDashboard, language: "en" },
    { path: "/en/order-management", element: AgencyDashboard, language: "en" },
    { path: "/en/invoices", element: AgencyDashboard, language: "en" },
    { path: "/en/purchase-code", element: AgencyDashboard, language: "en" },
    { path: "/en/payments", element: AgencyDashboard, language: "en" },
    { path: "/en/report-analysis", element: AgencyDashboard, language: "en" },
    { path: "/en/agency-profile", element: AgencyDashboard, language: "en" },
]

export { publicRoutes, privateRoutes };