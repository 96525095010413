import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Row, Col, Card, CardBody } from "reactstrap";
import { showToast } from '../../components/Toast';
import { getAgencyCodeUsage } from '../../helper/backend-methods';
import ReactApexChart from 'react-apexcharts';
import _T from '../../locale/AgencyPortal/translator';


function Statistics() {
    const [loading, setLoading] = useState(true);
    const [componentData, setComponentData] = useState(null);
    const options = { labels: ["Used", "Saved", "Available"], plotOptions: { pie: { donut: { size: '75%' } } }, dataLabels: { enabled: false }, legend: { show: false }, colors: ['#ff3d60', '#fcb92c', '#C29F60'], }

    useEffect(() => {
        getAgencyCodeUsage().then(({ data }) => {
            if (data.status) {
                const codes = getAllCodesWithUsage(data.data.seeker_agency.codes ? data.data.seeker_agency.codes : [], data.data.agency_seekers ? data.data.agency_seekers : [])
                setComponentData({
                    series: [codes.used, codes.saved, codes.available],
                    total: codes.total,
                    used: codes.used,
                    saved: codes.saved,
                    available: codes.available,
                })
            }
            else {
                showToast("error", "Request Failed", data.message)
            }
            setLoading(false)
        }).catch((err) => {
            showToast("error", "Request Failed", err.message)
            setLoading(false)
        });
    }, [])

    const getAllCodesWithUsage = (all, some) => {
        const used = some.map((seeker) => {
            return seeker.user_codes.map((code) => {
                return code
            })
        }).flat().filter((o) => o.is_used).map((c) => { return c.code })
        const saved = some.map((seeker) => {
            return seeker.user_codes.map((code) => {
                return code
            })
        }).flat().filter((o) => !o.is_used).map((c) => { return c.code })
        const output = all.map((code, index) => {
            if (used.includes(code.code)) {
                return {
                    ...code,
                    used: 1
                }
            }
            else if (saved.includes(code.code)) {
                return {
                    ...code,
                    used: 2
                }
            }
            else {
                return {
                    ...code,
                    used: 0
                }
            }
        })
        return { total: output.length, used: output.filter((c) => { return c.used === 1 }).length, saved: output.filter((c) => { return c.used === 2 }).length, available: output.filter((c) => { return c.used === 0 }).length }
    }


    return (
        <Row className='bottom-cards-container'>
            <Col md={8} className='graph-container'>
                {
                    loading ?
                        <Card className='graph-card'>
                            <CardBody>
                                <h4 className="card-title mb-4">{_T("Umrah Package Analytics")}</h4>
                                <div className='text-center'>
                                    <div style={{ height: "220px", width: "220px", "border": "25px solid #ebebeb", borderRadius: "250px", margin: "0 auto" }}></div>
                                </div>
                                <Row className='stats-number-container'>
                                    <Col>
                                        <div className="text-center mt-4">
                                            <p className="mb-2 text-truncate">
                                                <i className="mdi mdi-circle text-primary font-size-10 me-1"></i>
                                                {_T("Total")}
                                            </p>
                                            <h5 className='mt-0'><Skeleton height={16} width={26} /></h5>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="text-center mt-4">
                                            <p className="mb-2 text-truncate">
                                                <i className="mdi mdi-circle text-danger font-size-10 me-1"></i>
                                                {_T("Used")}
                                            </p>
                                            <h5 className='mt-0'><Skeleton height={16} width={26} /></h5>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="text-center mt-4">
                                            <p className="mb-2 text-truncate">
                                                <i className="mdi mdi-circle text-warning font-size-10 me-1"></i>
                                                {_T("Saved")}
                                            </p>
                                            <h5 className='mt-0'><Skeleton height={16} width={26} /></h5>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="text-center mt-4">
                                            <p className="mb-2 text-truncate">
                                                <i className="mdi mdi-circle text-success font-size-10 me-1"></i>
                                                {_T("Available")}
                                            </p>
                                            <h5 className='mt-0'><Skeleton height={16} width={26} /></h5>
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card> :
                        <Card className='graph-card'>
                            <CardBody>
                                <h4 className="card-title mb-4">{_T("Umrah Package Analytics")}</h4>
                                {componentData ? (
                                    <>
                                        <div id="donut-chart" className="apex-charts">
                                            <ReactApexChart options={options} series={componentData.series} type="donut" height="250" />
                                        </div>
                                        <Row className='stats-number-container'>
                                            <Col>
                                                <div className="text-center mt-4">
                                                    <p className="mb-2 text-truncate">
                                                        <i className="mdi mdi-circle text-primary font-size-10 me-1"></i>
                                                        {_T("Total")}
                                                    </p>
                                                    <h5 className='mt-0'>{componentData.total}</h5>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="text-center mt-4">
                                                    <p className="mb-2 text-truncate">
                                                        <i className="mdi mdi-circle text-danger font-size-10 me-1"></i>
                                                        {_T("Used")}
                                                    </p>
                                                    <h5 className='mt-0'>{componentData.used}</h5>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="text-center mt-4">
                                                    <p className="mb-2 text-truncate">
                                                        <i className="mdi mdi-circle text-warning font-size-10 me-1"></i>
                                                        {_T("Saved")}
                                                    </p>
                                                    <h5 className='mt-0'>{componentData.saved}</h5>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="text-center mt-4">
                                                    <p className="mb-2 text-truncate">
                                                        <i className="mdi mdi-circle text-success font-size-10 me-1"></i>
                                                        {_T("Available")}
                                                    </p>
                                                    <h5 className='mt-0'>{componentData.available}</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <div className='d-flex align-items-center justify-content-center' style={{ width: "250px", height: "250px", margin: "0 auto", borderRadius: "250px", border: "25px solid #dddddd", marginBottom: "30px" }}>
                                            {_T("No data found")}
                                        </div>
                                        <Row className='stats-number-container'>
                                            <Col>
                                                <div className="text-center mt-4">
                                                    <p className="mb-2 text-truncate">
                                                        <i className="mdi mdi-circle text-primary font-size-10 me-1"></i>
                                                        {_T("Total")}
                                                    </p>
                                                    <h5 className='mt-0'>0</h5>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="text-center mt-4">
                                                    <p className="mb-2 text-truncate">
                                                        <i className="mdi mdi-circle text-danger font-size-10 me-1"></i>
                                                        {_T("Used")}
                                                    </p>
                                                    <h5 className='mt-0'>0</h5>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="text-center mt-4">
                                                    <p className="mb-2 text-truncate">
                                                        <i className="mdi mdi-circle text-warning font-size-10 me-1"></i>
                                                        {_T("Saved")}
                                                    </p>
                                                    <h5 className='mt-0'>0</h5>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="text-center mt-4">
                                                    <p className="mb-2 text-truncate">
                                                        <i className="mdi mdi-circle text-success font-size-10 me-1"></i>
                                                        {_T("Available")}
                                                    </p>
                                                    <h5 className='mt-0'>0</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </CardBody>
                        </Card>
                }
            </Col>
            <Col md={4} className='number-cards-container'>
                <Row>
                    <Col md={12}>
                        <Card className='number-card'>
                            <CardBody>
                                <div className="d-flex justify-between align-items-center">
                                    <div className="flex-1 overflow-hidden">
                                        <p className="text-truncate fs-18 mb-2 text-dark">{_T("Umrah Total")}</p>
                                        {
                                            loading ? <Skeleton height={22} width={35} /> :
                                                <h4 className="mb-0">{componentData ? componentData.total : 0}</h4>
                                        }
                                    </div>
                                    <div className="text-success" style={{ color: '#c09a58' }}>
                                        <i className={"ri-send-plane-line fs-22"} style={{ color: '#c09a58' }}></i>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <div className="text-truncate"><small><span className="text-muted">{_T("Total number of Umrah")}</span></small></div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={12}>
                        <Card className='number-card'>
                            <CardBody>
                                <div className="d-flex justify-between align-items-center">
                                    <div className="flex-1 overflow-hidden">
                                        <p className="text-truncate fs-18 mb-2 text-dark">{_T("Umrah Sold")}</p>
                                        {
                                            loading ? <Skeleton height={22} width={35} /> :
                                                <h4 className="mb-0">{componentData ? componentData.used : 0}</h4>
                                        }
                                    </div>
                                    <div className="text-success" style={{ color: '#c09a58' }}>
                                        <i className={"ri-send-plane-line fs-22"} style={{ color: '#c09a58' }}></i>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <div className="text-truncate"><small><span className="text-muted">{_T("Number of Umrah Sold")}</span></small></div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={12}>
                        <Card className='number-card'>
                            <CardBody>
                                <div className="d-flex justify-between align-items-center">
                                    <div className="flex-1 overflow-hidden">
                                        <p className="text-truncate fs-18 mb-2 text-dark">{_T("Umrah Available")}</p>
                                        {
                                            loading ? <Skeleton height={22} width={35} /> :
                                                <h4 className="mb-0">{componentData ? componentData.available : 0}</h4>
                                        }
                                    </div>
                                    <div className="text-success" style={{ color: '#c09a58' }}>
                                        <i className={"ri-send-plane-line fs-22"} style={{ color: '#c09a58' }}></i>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <div className="text-truncate"><small><span className="text-muted">{_T("Number of Umrah Available")}</span></small></div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

}
export default Statistics;


