import React, { useEffect, useState } from "react"
import { Card, Row, Col, CardBody, Table } from "reactstrap";
import { showToast } from "../../components/Toast";
import { getAgencyCodeUsage } from "../../helper/backend-methods";
import Pagination from "react-js-pagination";
import Skeleton from "react-loading-skeleton";
import _T from '../../locale/AgencyPortal/translator';


const PurchaseCode = () => {
  const [activePage, setActivePage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(true)
  const [codeData, setCodeData] = useState([])
  const [codeDataTemp, setCodeDataTemp] = useState([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    getAgencyCodeUsage().then(({ data }) => {
      if (data.status) {
        const codes = getAllCodesWithUsage(data.data.seeker_agency.codes ? data.data.seeker_agency.codes : [], data.data.agency_seekers ? data.data.agency_seekers : [])
        setCodeData(codes)
        setCodeDataTemp(codes)
        setTotal(codes.length)
      }
      else {
        showToast("error", "Request Failed", data.message)
      }
      setLoading(false)
    }).catch((err) => {
      showToast("error", "Request Failed", err.message)
      setLoading(false)
    });
  }, [])

  const getAllCodesWithUsage = (all, some) => {
    const used = some.map((seeker) => {
      return seeker.user_codes.map((code) => {
        return code
      })
    }).flat().filter((o) => o.is_used).map((c) => { return c.code })
    const saved = some.map((seeker) => {
      return seeker.user_codes.map((code) => {
        return code
      })
    }).flat().filter((o) => !o.is_used).map((c) => { return c.code })
    return all.map((code, index) => {
      if (used.includes(code.code)) {
        return {
          ...code,
          used: 1
        }
      }
      else if (saved.includes(code.code)) {
        return {
          ...code,
          used: 2
        }
      }
      else {
        return {
          ...code,
          used: 0
        }
      }
    })
  }


  const updateTableData = (page) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (activePage !== page) {
      setLoading(true);
      setActivePage(page)
      setOffset(30 * (page - 1))
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }

  const applyFilter = (type = "data") => {
    if (type === "data") {
      setLoading(true);
      const value = document.getElementById("codeSearch").value;
      const status = document.getElementById("codeStatus").value;
      let codes = codeData;
      if (value) {
        codes = codes.filter((c) => c.code.toLowerCase().includes(value.toLowerCase()) || c.code.toLowerCase() === value.toLowerCase())
      }
      if (status) {
        codes = codes.filter((c) => c.used === parseInt(status))
      }
      setActivePage(1);
      setOffset(0);
      setTotal(codes.length);
      setCodeDataTemp(codes);
      setTimeout(() => { setLoading(false); }, 1000);
    }
  }

  return (

    <div className="page-content">
      <div className="container-fluid">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row className="filter-container">
                  <Col md={3} className="mb-3">
                    <form onSubmit={(e) => { e.preventDefault(); applyFilter("data") }}>
                      <label htmlFor="codeSearch" className="d-block">{_T("Search Discount Code")}</label>
                      {/* <InputGroup > */}
                      <input id="codeSearch" className="rounded border-left-0 form-control admin-search" style={{ marginLeft: "-4px", zIndex: "0" }} type="search" placeholder={_T("Type here to search")} />
                      <button type="submit" className="admin-btn-search" style={{ zIndex: "1" }}><i className="ri-search-2-line search-icon"></i></button>
                      {/* </InputGroup> */}
                    </form>
                  </Col>
                  <Col md={3} className="mb-3">
                    <label htmlFor="codeStatus" className="d-block">{_T("Filter by Status")}</label>
                    <select id="codeStatus" className="form-select admin-select" onChange={(e) => { applyFilter("data") }}>
                      <option value={""}>{_T("All Statuses")}</option>
                      <option value={1}>{_T("Used")}</option>
                      <option value={2}>{_T("Saved")}</option>
                      <option value={0}>{_T("Available")}</option>
                    </select>
                  </Col>
                </Row>
                <div className="table-container">
                  <Table hover responsive="true">
                    <thead>
                      <tr>
                        <th className="fs-12 text-gray-500">{_T("Code")}</th>
                        <th className="text-right fs-12 text-gray-500">{_T("Discount")}</th>
                        <th className="text-center fs-12 text-gray-500">{_T("Limit")}</th>
                        <th className="text-center fs-12 text-gray-500">{_T("Usage")}</th>
                        <th className="text-center fs-12 text-gray-500">{_T("Status")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loading ?
                          [...Array(30)].map((a, i) => {
                            return <tr key={i}>
                              <td className="fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                              <td className="text-right fs-12 text-gray-500"><Skeleton height={12} width={80} /></td>
                              <td className="text-center fs-12 text-gray-500"><Skeleton height={12} width={80} /></td>
                              <td className="text-center fs-12 text-gray-500"><Skeleton height={12} width={80} /></td>
                              <td className="text-center fs-12 text-gray-500"><Skeleton height={12} width={90} /></td>
                            </tr>
                          })
                          :
                          codeDataTemp.length === 0 ?
                            <tr><td colSpan="5" className="text-center fs-12 fw-600"><span className="d-flex align-items-center justify-content-center"><i className="ri-error-warning-line text-danger fs-22 fw-300 me-2"></i>{_T(" You haven't purchased any discount codes.")}</span></td></tr> :
                            codeDataTemp.slice(offset, offset + 30).map((code, index) => {
                              return <tr key={index}>
                                <td className="fs-12 text-gray-500"><span className="badge-success badge-soft-success font-size-14 badge badge-pill ">{code.code}</span></td>
                                <td className="text-right fs-12 text-gray-500">{code.percentage}%</td>
                                <td className="text-center fs-12 text-gray-500"><span className={`badge-${code.one_time ? "success" : "warning"} badge-soft-${code.one_time ? "success" : "warning"} font-size-12 badge badge-pill`}>{code.one_time ? "Once" : "Multiple"}</span></td>
                                <td className="text-center fs-12 text-gray-500"><span className={`badge-${code.one_seeker ? "success" : "warning"} badge-soft-${code.one_seeker ? "success" : "warning"} font-size-12 badge badge-pill`}>{code.one_seeker ? "Once" : "Multiple"}</span></td>
                                <td className="text-center fs-12 text-gray-500"><span className={`badge-${code.used === 1 ? "danger" : code.used === 2 ? "warning" : "success"} badge-soft-${code.used === 1 ? "danger" : code.used === 2 ? "warning" : "success"} font-size-12 badge badge-pill`}>{code.used === 1 ? "Used" : code.used === 2 ? "Saved" : "Available"}</span></td>
                              </tr>
                            })
                      }
                    </tbody>
                  </Table>
                  <Row className="align-items-center pagination-container">
                    <Col md="6">
                      {
                        codeDataTemp.length ?
                          <span className="fs-14 fw-600 text-dark align-middle">{_T("Showing")} {(30 * (activePage - 1)) + 1} to {(30 * (activePage - 1)) + codeDataTemp.slice(offset, offset + 30).length} {_T("of")} {total} {_T("Results")}</span> :
                          <span className="fs-14 fw-600 text-dark align-middle">{_T("Showing 0 of 0 Results")}</span>
                      }
                    </Col>
                    <Col md="6" className="d-flex justify-content-end">
                      <nav>
                        <Pagination activePage={activePage} itemsCountPerPage={30} totalItemsCount={total} pageRangeDisplayed={3} onChange={updateTableData} itemClass={"page-item"} linkClass={"page-link"} firstPageText={"First"} lastPageText={"Last"} prevPageText={"Previous"} nextPageText={"Next"} innerClass="pagination m-0" />
                      </nav>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PurchaseCode;